import styled from "styled-components"
import React, { useEffect } from "react"
import { Link } from "gatsby"
import widths from "../utils/dimensions"
import { useStaticQuery, graphql } from "gatsby"
import colours from "../utils/colours"

let Ul = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    flex-direction: row;
  }
`
let Li = styled.li`
  height: 40px;
  margin: 0;
  display: flex;
  align-items: center;
  a{
    padding: 0;
    color: ${ colours.logoGrey };
    text-decoration: none;
    text-transform: uppercase;
    margin: 0;
    @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
      padding: 0 10px;
    }
  }
  &.active{
    a{
      color: ${ colours.blue };
    }
    &:hover{
      border-bottom: 1px solid ${ colours.blue };
    }
  }
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    border: 1px solid rgba(0,0,0,0);
    &:hover{
      border-bottom: 1px solid ${ colours.logoGrey };
    }
  }
`

let Contact = styled.li`
  border: 1px solid white;
  padding: 5px 15px;
  color: white;
  margin-top: 20px;
  text-align: center;
  border-radius: 20px;
  a{
    color: white;
    text-decoration: none;
    text-transform: uppercase;
  }
  &.active{
    border: 1px solid ${ colours.blue };
    a{
      color: ${ colours.blue }
    }
  }
`

let MenuItems = ({ setMenuOpen, location, menu, setScrollLock }) => {
  const data = useStaticQuery(graphql`
      {
          main: wpgraphql {
              menu(id: "TWVudToy") {
                  menuItems {
                      nodes {
                          label
                          url
                      }
                  }
              }
          }
      }
  `)

  function setMenu(){
    if(setMenuOpen) {
      setMenuOpen( false )
    }
    if(setScrollLock) {
      setScrollLock( false )
    }
  }
  let queryData = data.main

  let buttonActive
  if(location.pathname === '/contact/'){
    buttonActive = 'active'
  }else{
    buttonActive = ''
  }

  return(
      <Ul>

        {queryData.menu.menuItems.nodes.map((node, i) => {
          let active;
          (node.url === location.pathname) ? active = 'active' : active = ''
          return(
            <Li key={ i } className={ active }>
              <Link
                key={ i }
                to={ node.url }
                onClick={ setMenu }
              >
                { node.label }
              </Link>
            </Li>
            )
        })}

        {menu !== 'main' &&

          <Contact className={buttonActive}>
            <a href="/contact">Contact Us</a>
          </Contact>
        }
      </Ul>
  )
}
export default MenuItems
