import React from "react";
import MenuItems from "./menu-items"
import styled from "styled-components"
import widths from "../utils/dimensions"

let MenuContainer = styled.div`
  display: none; 
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: flex;
  }
`

export default ({ location }) => {
  return(
    <MenuContainer>
      <MenuItems
        location={ location }
        menu={ 'main' }
      />
    </MenuContainer>
  )
}


