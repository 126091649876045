import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram, faYoutubeSquare, faTwitterSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import colours from "./../utils/colours"

const StyledSocialIcons = styled.div`
  display: none;
  height: 100%;
  margin-top: 22px;
  @media screen and (min-width: 900px){
    display: flex;
    justify-content: center;
  }
`
const StyledSocialIcon = styled.div`
  width: 50px;
  height: 50px;
  padding: 6px;
  position: relative;
  transition: all .2s ease-in-out;
  &:first-of-type{
    margin-left: 0;
  }
  &:hover{
    background-color: ${colours.yellow};
    transform: scale(1.2);
    a{
      color: white;
    }
  }
  a{
    color: ${ colours.logoGrey };;
  }
  svg{
    width: 100%;
    height: 100%;
    color: inherit;
  }
  .svg-inline--fa.fa-w-20,
  .svg-inline--fa.fa-w-14{
    width: 100%;
  }
`

const SocialIcons = ({ className }) => (
  <StyledSocialIcons className={ className }>
    <StyledSocialIcon>
      <a
        href="https://www.facebook.com/ConnectedSwitchgear"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook link"
      >
        <FontAwesomeIcon icon={faFacebookSquare} alt="Facebook icon"/>
      </a>
    </StyledSocialIcon>
    <StyledSocialIcon>
      <a
        href="https://www.instagram.com/csgau/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram link"
      >
        <FontAwesomeIcon icon={faInstagram} alt="Instagram icon"/>
      </a>
    </StyledSocialIcon>
    <StyledSocialIcon>
      <a
        href="https://twitter.com/csg_au"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Twitter link"
      >
        <FontAwesomeIcon icon={faTwitterSquare} alt="Twitter icon"/>
      </a>
    </StyledSocialIcon>
    <StyledSocialIcon>
      <a
        href="https://www.linkedin.com/company/connected-switchgear/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Linkedin link"
      >
        <FontAwesomeIcon icon={faLinkedin} alt="Linkedin icon"/>
      </a>
    </StyledSocialIcon>
    <StyledSocialIcon>
      <a
        href="https://www.youtube.com/channel/UCGPQ5VMsAytf-LIvcTG3mZg"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Youtube link"
      >
        <FontAwesomeIcon icon={faYoutubeSquare} alt="Youtube icon"/>
      </a>
    </StyledSocialIcon>
  </StyledSocialIcons>
)
export default SocialIcons
  
