// Theme/brand colours
let colours = {
  blue: '#3AA7E5',
  darkBlue: '#0B1222',
  red: '#E63B62',
  lightGrey: '#D8D6D6',
  mediumGrey: '#A39BA8',
  charcoal: '#223843',
  highlightGrey: '#F0F0F0',
  tileGrey: '#E5E7ED',
  footerBackground: '#223843',
  bodyBackground: '#171717',
  mobileMenu: '#161825',
  logoGrey: '#D8D6D6',
}
export default colours