import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import MobileMenu from "./menu-mobile"
import Menu from "./menu"
import widths from "../utils/dimensions"
import { useStaticQuery, graphql } from 'gatsby'
import colours from "../utils/colours"
import Img from "gatsby-image"

const SiteHeader = styled.div`
  width: 100%;
  background-color: black;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    position: absolute;
    display: inline-block;
    top: 0;
    z-index: 1;
    background-color: rgba(0,0,0,.4);
  }
`
const SiteHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  max-width: 1500px;
  overflow: hidden;
  height: 100%;
  position: relative;
  margin: auto;
  border-bottom: 1px solid ${ colours.logoGrey };
  padding: 20px 0;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    width: 95%;
    flex-direction: row-reverse;
  }
`
const Logo = styled.div`
  width: 150px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
let CtaButton = styled(Link)`
  display: none;
  @media screen and (min-width: 800px){
    display: inline-block;
    width: 180px;
    height: 40px;
    border: 1px solid ${props => props.iscontact ? colours.blue : 'white'};
    color: ${props => props.iscontact ? colours.blue : 'white'};
    background-color: rgba(0,0,0,0);
    border-radius: 20px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    text-transform: uppercase;
    font-size: 14px;
    &:hover{
      color: ${ colours.blue };
      border: 1px solid ${ colours.blue };
    }
  }
`

const Header = ({ location }) => {
  let isHome, isContact
  (location.pathname === '/') ? isHome = true : isHome = false;
  (location.pathname === '/contact') ? isContact = 'contact' : isContact = '';

  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              themeGeneralSettings {
                  themeOptions {
                      logo {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid(maxWidth: 900) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                      
                  }
              }
          }
      }
  `)
  let image = data.wpgraphql.themeGeneralSettings.themeOptions.logo

  return(
  <>
    <SiteHeader isHome={ isHome } id='site-header'>
      <SiteHeaderInner>
        <MobileMenu
          location={ location }
        />
        <CtaButton
          to={ '/contact' }
          iscontact={ isContact }
        >
          Contact Us
        </CtaButton>
        <Menu
          location={ location }
        />
        <Link to="/" aria-label="Homepage link">
          <Logo>
            <Img
              fluid={ image.imageFile.childImageSharp.fluid }
              alt={ image.altText }
            />
          </Logo>
        </Link>
      </SiteHeaderInner>
    </SiteHeader>
  </>
)}

export default Header
