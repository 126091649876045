import React from "react";
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer"
import styled, { createGlobalStyle } from "styled-components";
import HomeVideoBanner from "./home-video-banner"

const GlobalStyle = createGlobalStyle`
  body{
    background-color: white;
  }
`

let Main = styled.div`
  color: black;
  width: 100%;
  position: relative;
  min-height: calc(100vh - 1142px);
`

const Layout = ({ children, location }) => {
  return (
    <>
      <GlobalStyle/>
      <Header location={ location }/>

        {(location.pathname === '/') &&

          <HomeVideoBanner/>

        }

      <Main location={ location }>

        { children }

      </Main>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
