import React, { useRef } from "react"
import styled from "styled-components"
import widths from "../utils/dimensions"
import DownArrowIcon from '../images/down-arrow-thin.svg'
import colours from "../utils/colours"
import { graphql, useStaticQuery } from "gatsby"

let Banner = styled.div`
  width: 100%;
  background-color: black;
  overflow: hidden;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    height: 100vh;
  }
`
let Desktop = styled.div`
  display: none;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: inline-block;
    width: 100%;
    height: 100%;
    video{
      object-fit: cover;
      width: 100%;
      height: 100%;
    } 
  }
`
let Mobile = styled.div`
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: none; 
  }  
`
let DownArrow = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: bounce 2s infinite;
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  svg{
    fill: ${ colours.logoGrey };
  } 
`

const HomeVideoBanner = () => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              pageBy(uri: "home") {
                  homepage {
                      videoMobile {
                          mediaItemUrl
                          mediaFile {
                              publicURL
                          }
                      }
                      videoMobilePoster{
                          sourceUrl
                      }
                      videoDesktop {
                          mediaItemUrl
                          mediaFile {
                              publicURL
                          }
                      }
                  }
              }
          }
      }
  `)
  let elData = data.wpgraphql.pageBy.homepage
  let bannerRef = useRef()
  function scrollDown(){
    window.scrollTo({ behavior: 'smooth', top: bannerRef.current.clientHeight })
  }

  return(
    <Banner ref={ bannerRef }>
      <Desktop>
        <video
          autoPlay={ true }
          loop
          poster={ elData.videoMobilePoster.sourceUrl }
        >
          <source src={ elData.videoDesktop.mediaFile.publicURL } type="video/mp4" />
        </video>
        <DownArrow onClick={ scrollDown }>
          <DownArrowIcon/>
        </DownArrow>
      </Desktop>
      <Mobile>
        <video
          autoPlay={ false }
          controls
          poster={ elData.videoMobilePoster.sourceUrl }
          preload="none"
        >
          <source src={ elData.videoMobile.mediaFile.publicURL } type="video/mp4" />
        </video>
      </Mobile>
    </Banner>
  )
}
export default HomeVideoBanner
