// Site wide dimensions
let widths = {
  contentWidth: '1250px',
  contentWidthPage: '850px',
  contentWidthMedium: '977px',
  breakPointSmall: '600px',
  breakPointSmallMedium: '850px',
  breakPointMedium: '1280px',
  mobileMenuBreakPoint: '1200px',
}
export default widths