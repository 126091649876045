import styled from "styled-components"
import React, { useState } from "react"
import colours from "./../utils/colours"
import ClickOutHandler from 'react-onclickout'
import widths from "../utils/dimensions"
import MenuItems from "./menu-items"
import { RemoveScroll } from 'react-remove-scroll';

let Container = styled.div`
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: none;
  }  
`
let HamburgerOuter = styled.div`
  width: 150px;
  height: 100%;
  display: block;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: none;
  } 
`
let Hamburger = styled.div`
  width: 50px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  span{
    width: ${props => props.menuOpen ? '40px' : '100%'};
    height: 2px;
    position: absolute;
    background-color: white;
    margin-left: ${props => props.menuOpen ? '6px' : '0'};
    transition: 0.3s ease-in-out;
    left: 0;
    &:nth-of-type(1){
      top: ${props => props.menuOpen ? '14px' : '0'};
      transform: rotate(${props => props.menuOpen ? '45deg' : '0deg'});
    }
    &:nth-of-type(2){
      opacity: ${props => props.menuOpen ? 0: 1};
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3){
      bottom: ${props => props.menuOpen ? '14px' : '0'};
      transform: rotate(${props => props.menuOpen ? '-45deg' : '0deg'});
    }
  }
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: none;
  }
`
let Menu = styled.div`
  width: ${props => props.menuOpen ? '300px': '0'};
  height: 100%;
  position: fixed;
  background-color: ${colours.mobileMenu};
  top: 0;
  left: ${props => props.menuOpen ? '0': '-300px'};
  z-index: 2;
  padding: 25px 50px;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: ${ widths.mobileMenuBreakPoint }){
    display: none;
  }
`

let MobileMenu = ({ className, location }) => {
  let [menuOpen, setMenuOpen] = useState(false)
  let [scrollLock, setScrollLock] = useState(false)

  function menuClick(){
    if(menuOpen){
      setMenuOpen(false)
      setScrollLock(false)
    }else{
      setMenuOpen(true)
      setScrollLock(true)
    }
  }

  function menuClickOutside(){
    setMenuOpen(false)
    setScrollLock(false)
  }

  return(
    <Container>
      <RemoveScroll
        enabled={ scrollLock }
      >
        <ClickOutHandler onClickOut={menuClickOutside} className={ className }>
          <HamburgerOuter>
            <Hamburger
              menuOpen={menuOpen}
              onClick={menuClick}
            >
              <span/>
              <span/>
              <span/>
            </Hamburger>
          </HamburgerOuter>
          <Menu menuOpen={menuOpen}>
            <MenuItems
              setMenuOpen={ setMenuOpen }
              location={ location }
              menu={ 'mobile' }
              setScrollLock={ setScrollLock }
            />

          </Menu>
        </ClickOutHandler>
      </RemoveScroll>
    </Container>
  )
}
export default MobileMenu
