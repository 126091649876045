import React from "react"
import styled from "styled-components"
import { Link } from 'gatsby'
import SocialIcons from "./social-icons";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image"
import colours from "../utils/colours"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

let StyledFooter = styled.footer`
  background-color: ${ colours.darkBlue };
  color: white;
  padding-top: 100px;
`
let H5 = styled.h5`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 5px;
`

let Contact = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 30px;
`
let ContactTile = styled.div`
  width: 100%;
  max-width: 250px;  
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a{
    color: inherit;
    text-decoration: none;
    text-align: center;
    &:hover{
      text-decoration: underline;
    }
  }
`
let StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 50px !important;
  height: 50px;
  margin: 0 auto 10px auto;
  color: ${ colours.logoGrey };
`
let PhoneEmail = styled.div`
  text-align: center;
`
let FooterLogo = styled.div`
  width: 100%;
  text-align: center;
  padding-bottom: 60px;
  .gatsby-image-wrapper{
    width: 300px;
    margin: auto;
  }
`
let Copyright = styled.div`
  width: 90%;
  max-width: 600px;
  margin: auto;
  padding: 30px 0 20px 0;
  text-align: center;
  color: ${ colours.logoGrey };
  a{
    color: ${ colours.logoGrey };;
    &:hover{
      color: white;
    }
  }
`

let Footer = () => {
  const data = useStaticQuery(graphql`
      {
          wpgraphql {
              themeGeneralSettings {
                  themeOptions {
                      copyright
                      footerLogo {
                          sourceUrl
                          altText
                          imageFile {
                              childImageSharp {
                                  fluid(maxWidth: 900) {
                                      ...GatsbyImageSharpFluid_withWebp
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
  `)
  let image = data.wpgraphql.themeGeneralSettings.themeOptions.footerLogo

  return (
    <StyledFooter>
      <FooterLogo>
        <Link to={ '/' }>
          <Img
            fluid={ image.imageFile.childImageSharp.fluid }
            alt={ image.altText }
          />
        </Link>
      </FooterLogo>
      <H5>Get in touch</H5>
      <Contact>
        <ContactTile>
          <a href="tel:1300071378">
            <StyledFontAwesomeIcon icon={ faPhone } alt="Phone icon"/>
            <PhoneEmail>1300 071 378</PhoneEmail>
          </a>
        </ContactTile>
        <ContactTile>
          <a href="mailto:info@itouchhome.com.au">
            <StyledFontAwesomeIcon icon={ faEnvelope } alt="Email icon"/>
            <PhoneEmail>info@itouchhome.com.au</PhoneEmail>
          </a>
        </ContactTile>
      </Contact>

      <SocialIcons/>
      <Copyright>
        © {new Date().getFullYear()} &nbsp;
        <span dangerouslySetInnerHTML={{ __html: data.wpgraphql.themeGeneralSettings.themeOptions.copyright }}/>
        <Link to={'/terms'}>Terms & Conditions</Link>. <Link to={'/privacy'}>Privacy Policy</Link> By <a href="https://copelandcreative.com.au/" target="_blank" rel="noopener noreferrer">Copeland Creative</a>.
      </Copyright>
    </StyledFooter>
  )
}
export default Footer